<template>
  <div class="goodsSalesList">
    <!-- 查詢 -->
    <section class="table-search">
      <div style="display: flex">
        <el-date-picker
          v-model="dateToDate"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="~"
          start-placeholder="创建时间"
          end-placeholder="创建时间"
          :picker-options="pickerOptions"
          @change="goChangeDate()"
          clearable
          value-format="yyyy-MM-dd"
          class="mr15"
        >
        </el-date-picker>
        <div class="search-L">
          <el-select
            v-model="tableParams.orderGoodsIdSet"
            placeholder="请选择商品"
            clearable
            @change="goSelectChange()"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in goodsList"
              :key="item.orderGoodsId"
              :label="item.goodsName"
              :value="item.orderGoodsId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goClick(0)">导出</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
    v-if="tableData.length"
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="推销人" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已选商品" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsNames }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实付总金额" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="推销总金额" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.performanceAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="推销笔数" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.performanceNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(1, scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-empty description="请先选择商品，才可进行统计～" v-else></el-empty>

    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
    <!---->
    <el-dialog
      title="商品业绩详情"
      :visible.sync="isShowDetail"
      :close-on-click-modal="false"
      append-to-body
      width="1000px"
    >
      <el-row :gutter="20">
        <el-col :span="8"
          >时间段：{{ tableParams.dateFrom }} ~ {{ tableParams.dateTo }}</el-col
        >
        <el-col :span="8">推销人：{{ advanceInfo.nickName }}</el-col>
        <el-col :span="8">已选商品：{{ tableParams.goodsNames }}</el-col>
        <el-col :span="8">实付总金额：{{ advanceInfo.paymentAmount }}</el-col>
        <el-col :span="8"
          >推销总金额：{{ advanceInfo.performanceAmount }}</el-col
        >
        <el-col :span="8">推销笔数：{{ advanceInfo.performanceNum }}</el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        :data="itemData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="下单时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.paymentDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}｜{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.goodsInfo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单位置" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.roomName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="送达时间" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.reservationFlg"
              >{{ scope.row.reservationStartTime }}～{{
                scope.row.reservationEndTime
              }}</span
            >
            <span v-else>立即送达</span>
          </template>
        </el-table-column>
        <el-table-column label="订单备注" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付方式" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.paymentInfo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实付金额" align="center">
          <template slot-scope="scope">
            <span>{{
              (scope.row.paymentPrice + scope.row.balanceAmount).toFixed(2)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="推销金额" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.performanceAmount }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="itemTotal"
          :page-size="itemParams.size"
          @size-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="itemCurrentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { formatterDate } from "@/utils/utils";
import {
  getPerformanceList,
  getPerformanceDetail,
  getGoodsList,
  goExportPerformance,
} from "@/api/serve/serve.js";
export default {
  name: "goodsSalesList",
  data() {
    return {
      pickerOptions,
      formatterDate,
      tableData: [],
      total: 0,
      tableParams: {
        businessType: 3,
        page: 1,
        size: 10,
        dateFrom: "",
        dateTo: "",
        orderGoodsIdSet: [],
        goodsNames: "",
        shopId: "",
        merchantId: "",
      },
      currentPage: 1,
      isShowDetail: false,
      dateToDate: [],
      goodsList: [],

      itemData: [],
      itemTotal: 0,
      itemCurrentPage: 1,
      advanceInfo: {},
      itemParams: {
        page: 1,
        size: 10,
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.initDate();
    this.getGoodsList();
  },
  methods: {
    // 导出业绩列表
    goExportPerformance() {
      let name =
        this.tableParams.dateFrom +
        "～" +
        this.tableParams.dateTo +
        this.tableParams.goodsNames +
        "业绩";
        goExportPerformance(this.tableParams,name);
    },
    // 商品列表查询
    getGoodsList() {
      let params = {
        shopId: this.tableParams.shopId,
        merchantId: this.tableParams.merchantId,
      };
      getGoodsList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.goodsList = res.data;
        }
      });
    },
    // 获取已选商品名称
    goSelectChange() {
      if (this.tableParams.orderGoodsIdSet.length) {
        let arr = [];
        this.goodsList.forEach((m) => {
          this.tableParams.orderGoodsIdSet.forEach((n) => {
            if (m.orderGoodsId == n) {
              arr.push(m.goodsName);
            }
          });
        });
        this.tableParams.goodsNames = arr.join(",");
        this.reload();
      } else {
        this.tableData = [];
      }
    },
    // 商品业绩列表
    getPerformanceList() {
      getPerformanceList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 商品业绩详情
    getPerformanceDetail(advanceId) {
      let params = {
        page: this.itemParams.page,
        size: this.itemParams.size,
        dateFrom: this.tableParams.dateFrom,
        dateTo: this.tableParams.dateTo,
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        advanceId: advanceId,
        orderGoodsIdSet: this.tableParams.orderGoodsIdSet,
        businessType: 3,
      };
      getPerformanceDetail(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemData = res.data.list;
          this.itemTotal = res.data.total;
        }
      });
    },
    // 初始化日期
    initDate() {
      // 获取当月第一天
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      this.tableParams.dateFrom = this.formatterDate(new Date(y, m, 1));
      this.tableParams.dateTo = this.formatterDate(new Date());
      this.dateToDate = [this.tableParams.dateFrom, this.tableParams.dateTo];
    },
    // 选择日期
    goChangeDate() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      }
      if (this.tableParams.orderGoodsIdSet.length) {
        this.reload();
      }
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPerformanceList();
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 0:
          if (this.tableParams.orderGoodsIdSet.length) {
            this.goExportPerformance();
          } else {
            this.$message({
              message: "请先选择商品",
              type: "error",
            });
          }
          break;
        case 1:
          this.advanceInfo = row;
          this.getPerformanceDetail(row.advanceId);
          this.isShowDetail = true;
          break;
      }
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPerformanceList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.size = pageSize;
      this.reload();
    },

    // 【监听】详情表格分页点击
    onItemCurrentPage(page) {
      this.itemParams.page = page;
      this.getPerformanceDetail();
    },

    // 【监听】详情表格条数点击
    onItemSizeChange(pageSize) {
      this.itemParams.size = pageSize;
      this.itemParams.page = 1;
      this.itemCurrentPage = 1;
      this.getPerformanceDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsSalesList {
  padding: 0.15rem;
  background: #fff;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
.el-col {
  margin-bottom: 20px;
}
</style>